// prettier-ignore
export const zh = {
  buttons: {
    add_payment_receipt: '添加付款收据',
    add_towing_details: '添加吊车详情',
    add_watchlist: '加入观看列表',
    apply: '套用',
    attend: '出席',
    back_to_login: '返回登录页面',
    back_to_sign_in: '返回登录页面',
    back: '返回',
    bid_no_sale: '没有销售',
    bid_now: '现在竞标',
    buyer_registration: '买家注册',
    cancel: '取消',
    change_password: '更改密码',
    choose_from_library: '图库',
    clear_filters: '清除筛选',
    clear: '清除',
    close: '关闭',
    confirm: '确认',
    contact_us_now: '立即联系我们',
    contact_us: '联系我们',
    continue: '继续',
    create_account: '创建账号',
    deposit_new: '+',
    deposit: '充值',
    details: '详情',
    directions: '地址',
    done: '完成',
    download_list: '下载清单',
    download: '下载',
    e_bidding: '网上竞标',
    filters: '筛选',
    forgot_password: '忘记密码',
    get_started: '入门指南',
    go_back: '回去',
    live_auction: '实时拍卖',
    log_in: '登录',
    logout: '登出',
    make_payment: '上传付款单',
    menu: '选单',
    new_item: '新物品',
    next: '接着',
    offer: '出价',
    ok: '是',
    pre_bid_now: '现在预先竞标',
    pre_bid: '预先竞标',
    proceed: '继续',
    record_again: '记录',
    register: '注册',
    remove_watchlist: '从观看列表中删除',
    reset_password: '更改密码',
    reset: '重置',
    retake: '重拍',
    save_note: '储存笔记',
    save: '储存',
    search: '查找',
    sell_with_us: '跟我们一起卖',
    send_email: '发送邮件',
    send_message: 'Send message',
    set_max_bid:'设置最高自动出价',
    set_pre_bid: '预先设置标价',
    show_all: '显示所有',
    sign_in: '登录',
    sign_up: '注册',
    skip: '跳过',
    sold: '已售出 ({{badge}})',
    submit: '提交',
    switch_environment: '环境切换',
    take_photo: '拍照',
    top_up: '立即充值!',
    update_bid: '更改出价',
    update_max_bid: '更新最高出价',
    update: '更新',
    upload: '上传',
    view_all_auctions: 'View All Auctions',
    view_all_items: 'View All Assets',
    view_all: '查看所有',
    view_details: '查看详情',
    view: '查看',
    watchlist: '收藏列表',
    withdraw: '撤回',
    withdrawn: '撤回',
  },
  labels: {
    _balance: '余额',
    '03WD': ' 3 工作日',
    '05D': ' 5 天',
    '05WD': ' 5 工作日',
    '07D': ' 7 天',
    '07WD': ' 7 工作日',
    '10D': ' 10 天',
    '14D': ' 14 天',
    '30D': ' 30 天',
    // TODO: industrial_auction_list: '...拍卖列表',
    // TODO: salvage_auction_list:'...拍卖列表',
    account_type_desc:'请选择以下一项',
    account_type_header: '账号类型',
    account_type_titel: '选择您的账号类型',
    actions: '行动',
    add_money: '充值',
    add_note: '添加笔记',
    add_photo: '添加照片',
    added_to_watchlist: '已添加至收藏列表',
    agree: '我同意 ',
    all_deposits_refundable: '如果您没有获标,所有保证金将 100% 退还。',
    amount_amt: '待付金额',
    amount_caps: '金额',
    amount_left: '剩余金额',
    amount_paying: '支付金额',
    amount: '数额',
    applied: '应用',
    approved: '批准',
    asking_bid: '询价出价',
    at_least_8_characters: '您的密码必须至少8个字符。',
    auction_ended: '拍卖结束',
    auction_live: '拍卖进行中',
    auction_remarks: '拍卖备注',
    auction: '拍卖',
    available_balance: '可用余额',
    badge: '徽章',
    balance_available_new: '即将开始',
    balance_available: '可用余额 {{value}}',
    balance_hold_new: ' 等候拍卖',
    balance_hold: '{{value}} 暂时冻结',
    balance: '账户余额',
    be_first: '成为第一个！',
    begin_shortly: '请稍等,拍卖会即将开始。',
    bid_increment: '标价增量',
    bid_not_available: '出价无效',
    bid_this_amount: '按此金额投标',
    bidding_bid_won: '成功赢取此投标',
    bidding_view_auctions: '查看拍卖',
    bidding_disabled: '禁用竞标',
    bidding_enabled: '启用竞标',
    bidding_ended: '拍卖结束',
    bidding_hold: '尚未上架',
    bidding_live: '实时拍卖',
    bidding_lost: '此投标已失败',
    bidding_lotted: '已上架',
    bidding_offer: '您已出价',
    bidding_outbid: '您的出价低正落后',
    bidding_unknown: '未知',
    bidding_highest_bidder: '最高出价者',
    bidding_winning: '您的出价正领先',
    bidding_won: '您已成功赢取此投标',
    blocked_amount: '暂时冻结余额',
    blocked_balance_alt: '冻结余额',
    blocked_balance: '封锁',
    blocked_deposit_lowercase: '冻结保证金',
    blocked_deposit: '冻结抵押金',
    broker: '中间商（我是购买给其他人）',
    build: '制作',
    by_location: '按地点',
    by_make: '按品牌',
    by_model: '按型号',
    by_type: '按物品类型',
    cant_find: '未找到“{{value}}”搜索的车辆.',
    car_auction: '二手汽车拍卖列表',
    category: '类别',
    change_img: '身份证或护照\n',
    change_password_lowercase: '修改密码',
    change_password: '更改密码',
    chassis_number: '车辆识别号码',
    check_image: '检查图像质量',
    check_your_mail: '请检查您的电子邮件',
    choose_identity_card: '选择身份证',
    choose_payment_proof: '选择付款证明',
    closed: '已结束',
    color: '颜色',
    condition_type: '状况类别',
    condition: '状态',
    congratulation: '恭喜',
    contain_lowercase_case: '您的密码必须至少包括一个小写字母。',
    contain_upper_case: '您的密码必须至少包括一个大写字母。',
    contract: '合约',
    copy_link: '或者复制链接',
    create_account: '创建账号',
    currency_rm: 'RM',
    current_balance: '当前余额',
    current_bid_lowercase: '目前标价',
    current_bid: '当前出价',
    current_status: '当前状态',
    date: '日期',
    dealer_broker_info: '“我是专业的买家/卖家”',
    dealer: '经销商（我是专业买家）',
    deals_money: '寻找优惠。创造利润。',
    deposit_amount: '押金',
    deposit: '充值',
    device_unique_id: 'Device UID',
    disabled: '禁用',
    documents: '文件',
    dont_have_account: '没有账号?',
    dot: ' • ',
    drawer_about: '关于我们',
    drawer_auctions: '拍卖',
    drawer_e_bidding_auction: '网上竞标拍卖',
    drawer_ebids: '我的电子投标',
    drawer_faq: '常见问题解答',
    drawer_help_and_support: '服务与协助',
    drawer_live_auction: '实时拍卖',
    drawer_my_bids: '我的标价记录',
    drawer_offers_history: '我的出价记录',
    drawer_prebids: '我的最高自动出价',
    drawer_purchase_history: '我的购买记录',
    drawer_purchase: '我的购物清单',
    drawer_purchase_v2: '购买与出价',
    drawer_search_inventory: '搜索庫存',
    drawer_service: '附加服务',
    drawer_terms_and_conditions: '条款和条件',
    drawer_title: '导航',
    drawer_transactions: '我的交易记录',
    drawer_watchlist: '我的收藏列表',
    due_amt: '未付帐款',
    email_address: '电子邮箱地址',
    email_not_valid: '请输入正确邮址',
    email_sent: '电子邮件已发送',
    empty_fullname: '请输入您的全名',
    empty_username: '请输入正确用户名',
    enabled: '启用',
    end_in: '剩余',
    end_time: '结束时间',
    end: '终止于 {{date}}',
    engine_number: '车辆引擎号码',
    enter_bid_amount: '输入标价',
    e_bidding: '网上竞标',
    failed_deposit: '若您在付款时发生未知错误 ({{error}}), 请重试或向您的银行进行查询。若交易仍然遭拒, 您可致电+60166999170与我们的客户服务联系。',
    filtered_items: '选项物品',
    have_account: '已有账号？ ',
    hello_user: '您好 {{name}},',
    high_bid: '最高标价',
    highest_bid_amount: '最高投标金额',
    highest_bidder: '您是最高出价者',
    ic_required: '需要身份证或护照照片',
    id: 'ID',
    incident_type: '意外种类',
    increase_max_bid: '增添最高出价',
    inspection_remarks: '检验备注',
    inspection_report: '检测报告',
    insufficient_balance: 'Insufficient Balance',
    insufficient_deposit: '抵押金不足',
    insurance_report: '汽车保险报告',
    intake_origin: '进口来源',
    item_details: '物品详情',
    item_live: '实时拍卖',
    item_name: '物品名称',
    item_on_hold: '等候拍卖',
    item_prebid_label: '预先出价',
    item_static: '网上拍卖',
    item: '物品',
    items: '物品',
    key: '锁匙',
    last_bid: '您的最后出价',
    last_step_desc: '订阅我们的电子邮件列表,抢先获取即将出售的物品最新消息。',
    last_step_header: '即将完成了！',
    last_step_title: '永远不会错过任何优惠',
    latest_auctions: 'Latest Auctions',
    left: '剩余',
    live_auction_terms: '所有出价均以“原样”为准,需符合本公司条规与条款',
    live_now: '同步直播',
    live: '实时拍卖',
    LIVE: '实时',
    location: '地点',
    lot_current_bid: '目前标价',
    lot_deposit: '抵押金',
    lot_is_on_hold: '此物品状态为搁置中,待拍卖',
    lot_number: '拍卖品',
    lot_starting_price: '开价',
    lot_stock_number: '物品编号: {{stock}} •  拍卖品: {{lot}}',
    lost: '您输了',
    make: '品牌',
    max_bid: '您的最高出价',
    model: '型号',
    my_bids: '我的出价',
    my_last_bid: '我的最后出价',
    my_last_bids: '我的电子投标',
    my_max_bid: '我的最高出价',
    my_prebid: '我的预先标价',
    my_prebids:'我的预出价',
    my_watchlist: '我的收藏列表',
    n_a: 'N/A',
    never: '绝不',
    new_added: '新增',
    next_bid_lowercase: '下一个投标',
    next_bid: '下一个标价',
    next_steps: '检查您的电子邮件中是否有单据',
    no_auctions_yet: '尚未有任何拍卖会',
    no_auctions: '目前没有拍卖会。',
    no_blocked_deposits: '您没有冻结抵押金。',
    no_e_bidding_auctions: '目前没有线上拍卖会。',
    no_ebids_yet: '您尚未有任何电子投标',
    no_ebids: '您尚未有任何电子投标',
    no_fees: '零费用',
    no_items_yet: '没有物品',
    no_items: '此物品不在此拍卖会中',
    no_live_auctions: '目前没有实时拍卖会。',
    no_my_bids: '您尚未有任何出价',
    no_offer_yet: '您尚未有任何出价记录',
    no_offers: '您尚未有任何出价记录',
    no_prebid_yet: '您尚未有任何最高自动出价',
    no_prebids: '您尚未有任何最高自动出价',
    no_price: 'RM -',
    no_purchase_yet: '您尚未有任何购买记录',
    no_purchases: '您尚未有任何购买记录',
    no_result_found: '没有搜索结果',
    no_sale: '没有销售',
    no_search_result: '没有搜索结果',
    no_transactions: '您尚未有任何交易记录',
    no_watchlist: '您尚未在收藏列表添加任何物品',
    now_bidding: '现在竞标',
    offer_amount: '出价',
    offer_for: '出价为{{amount}}',
    offer_this_amount: '按此金额报价',
    offers: '出价',
    one_touch_bidding_disabled: '单击以禁用竞标',
    one_touch_bidding_enabled: '单击以启用竞标',
    or: '或者',
    paid: '已付款',
    paid_on: '已付款日期',
    partial_submitted: '部分提交',
    password_not_match: '您的密码不匹配',
    password_rules: '您的密码必须至少包含8个字符,1个大写字母,1个小写字母和1个数字！',
    password_to_switch_environment: '输入密码以切换环境。',
    password: '密码',
    pay_by: '付款截止日期',
    pay_by_date: '按日期付款',
    payable_to_pickles: '应付Pickles',
    payable_to_seller: '应付给卖方',
    payment_due: '付款截止',
    payment_instructions: '付款说明',
    payment_pending: '付款等待中',
    payment_proof: '付款凭证',
    payment_ref_no: '付款参考号码',
    payment_submission_history: '付款提交历史记录',
    pending_account: '您的账号正在等待批准',
    pending_approval: '等待批准',
    personal_details: '个人身份证或护照',
    personal_id: '帐户资料',
    phone_not_valid: '请输入正确手机号码',
    please_fill_all_fields: '请填写所有栏目',
    prebid_label: '预先出价',
    profile_details: '帐户资料详情',
    profile_info: '创建账号',
    profile: '帐户',
    public_info: '我为自己或家人买/卖',
    public: '公众（我是购买给自己或家人）',
    read_and_agree: '我已细读并同意此',
    recent_industrial_items: '最近添加的工业资产',
    recent_salvage_items: '最近添加的事故(SALVAGE)资产',
    recent_used_cars: '最近添加的二手车',
    recent_used_motorcycles: '最近添加的二手摩托车',
    recommended_auctions: '为您推荐',
    reg_number: '车辆登记号码',
    register_complete: '您的账号已完成',
    rejected: '已拒绝',
    remember_me: '记得我',
    removed_from_watchlist: '从收藏列表中移除',
    reserve: '保留',
    reserved_price: '保留价',
    reset_password: '更改密码',
    search_our_inventory: '搜索我们的库存',
    select_payments: '请选择以下付款方式：',
    selected: '已选择',
    sending_bid: '发送投标...',
    set_max_bid_lowercase: '设置最高自动出价',
    share_via: '分享通过',
    sign_in: '登录',
    sign_me_weekly: '是的,我要订阅周刊 ...',
    sign_me: '是的,我要订阅 ...',
    socket_id: '连接ID',
    sold_amt: '售价',
    sold_date: '售出日期',
    sold_on: '日期',
    sold_this_amount: '按此金额出售',
    sold: '已售出',
    sorry: '抱歉！',
    source_type: '来源种类',
    specifications: '细则',
    start_in: '开始于',
    start_price: '开价',
    start_soon: 'Starts soon',
    start_time: '开始时间',
    starting_bid: '起始出价',
    STATIC: '网上拍卖',
    status: '状况',
    stock_no: '物品代号',
    stock_number: '物品编号',
    submitted: '已提交',
    supported_payment_methods: '支持的付款方式',
    supporting_document: '所需文件#1',
    take_photo: '拍照',
    tap_to_bid: '点击出价',
    thanks_for_attend: '谢谢您的出席。',
    time_date: '时间/日期',
    time_out: '超时',
    time_remaining: '剩余时间',
    time: '时间',
    to_be_announced: '即将公布',
    today_lowercase: '今天',
    today: '今天',
    top_up_securely: '安全充值',
    top_up: '充值',
    total_bids: '出价次数',
    total_items: '全物品',
    tow_by: '拖出截止日期',
    transaction_history_lowercase: '交易历史',
    transaction_history: '交易记录',
    transactions: '交易',
    triple_dot: '•••',
    type_lowercase: '类型',
    type_of_doc: '文档类型',
    type: '类型',
    units: '单位',
    unpaid: '未付款',
    upcoming_auctions: '即将来临的拍卖会',
    update_require_message: '更新能为您带来新的功能。我们检测到您仍在使用着旧版本的应用程序。请安装最新版本,以能继续参与 Pickles 的在线竞标。',
    upload_avatar: '个人资料',
    upload_id: '+ 上传帐户照片',
    upload_receipt: '上传收据',
    uploaded_id: '护照图片',
    used_motor_auction_list: '二手摩托车拍卖列表',
    useful_links: 'Here are some helpful links instead:',
    variant: '引擎',
    vendor: '供应商',
    version: '版本',
    view_contract: '查看合同',
    view_history: '查看记录',
    view_time: '看车时间',
    welcome: '欢迎',
    withdraw: '撤回',
    yard_location: '物品所在位置',
    years_of_manufacture: '年份',
    you_are_logged_with: '您正登录此账号',
    you_available_deposit: '我的可用抵押金',
    you_highest: '您是最高竞标者。若您要增加最高出价,请按“我的最高出价',
    you_won: '您已获标！',
    you: '您',
    your_bid: '您的出价',
    your_deposit_has_been_received: '您的抵押金已收到。若您的余额在1分钟内未更新,请刷新您的页面。',
    your_max_bid: '您的最高出价',
    your_notes: '我的笔记',
    your_pre_bid: '您的预先出价',
    your_prebid_accepted: '您的最高出价{{amount}}已被接受',
    your_wallet: '我的钱包',
  },
  captions: {
    be_first_bidder: '成为第一个出价者!',
    check_auctions: '拍卖结束。如想查看更多拍卖,请前往主页!',
    eligible_for_offer: '符合出价条件!',
    eligible_to_buy: '符合购买条件!',
    highest_bidder: '您是最高出价者!',
    late_bid_placed: '您的出价迟了',
    lost: '您输了！',
    lotted: '物品已上架',
    offer: '出价',
    outbid: '您正落后',
    outbid_by_someone: '您的出价被其他人超过',
    send_bid_delay: '您的竞标处理时间比预期时间长。请稍等或检查您的网络连接。',
    start_bidding: '立即开始竞价!',
    top_up: '充值 {{amountToTopUp}} 以及出价!',
    updating_result: '正在更新投标结果...',
    winning: '您在赢着！',
    won: '你已赢了！',
  },
  fields: {
    account_status: '账户状态',
    bid_now: '现在竞标',
    brand: '品牌',
    confirm_new_password: '确认密码',
    confirm_password: '确认密码',
    current_password: '当前密码',
    dealer: '经销商（我是专业买家）',
    email_address: '电子邮箱地址',
    full_name: '全名',
    ic_number: '身份证号码',
    identity_card_selected: '身份证已被选择',
    identity_card: '身份证',
    ill_purchasing_items_as_a: '我会以。。。购买此物品',
    keyword: '关键词',
    location: '地点',
    make: '品牌',
    mobile_number: '手机号码',
    model: '型号',
    name: '姓名',
    new_email_address: '输入您的电子邮箱',
    new_password: '新密码',
    passport_number: '身份证/护照号码',
    passport: 'Enter your IC number or Passport',
    password: '密码',
    payment_ref_no: '输入付款参考号',
    phone_number: '手机号码',
    repeat_password: '重复密码',
    search_for_an_item: '搜索物品或拍卖...',
    search_input: '搜索物品和拍卖',
    search_watchlist: '搜索观看列表',
    search: '查找',
    select_auction_category: '拍卖类别',
    select_auction_type: '拍卖类型',
    select_condition_type: '选择状态类型',
    select_item_type: '选择物品类型',
    select_location: '选择地点',
    select_make: '选择品牌',
    select_model: '选择型号',
    select_type: '选择物品类型',
    turn_notification: '开启或关闭通知',
    username: '用户名',
    yard: '仓库',
    year_from: '年份从',
    year_to: '年份到',
    year: '年份',
    your_name: '您的名字',
  },
  infos: {
    account_pending_title_new: '您的账号仍在等待批准。',
    account_pending: '您的账户仍在等待批准。我们会在完成审核后通过电子邮件发送给您。',
    after_login: '您可以在登入后于此查看您的网上竞标日志活动',
    amount_paying_not_exceed_outstanding: '支付金额不得超过未偿还金额',
    auction_not_started: '该物品已准备销售,但是拍卖会尚未开始。若要在竞标前得到通知,请单击上方的收藏列表。',
    auction_started: '此物品的拍卖会已经开始竞标。若您要竞标此物品,您必须参加拍卖会。祝您好运！',
    check_payment_reference: '确保付款参考 ID 和金额清晰',
    current_bid: '目前标价',
    custom_bid_info_more_than: '通过设置最高出价,我们的系统将为您竞标,直到您的最高出价数额为止。若要设置最高出价,您必须出价多于{{nextValue}}或更高才能被接受。若您需要,您往后可以在调高您的最高出价数额。',
    custom_bid_info_prebid: '通过设置最高出价,我们的系统将为您竞标,直到您的最高出价数额为止。 若您需要,您往后可以在调高您的最高出价数额。',
    custom_bid_info: '通过设置最高出价,我们的系统将为您竞标,直到您的最高出价数额为止。若要设置最高出价,您必须出价至少{{nextValue}}或更高才能被接受。若您需要,您往后可以在调高您的最高出价数额。',
    drawer_not_logged: '请注册一个账户或登录,以享有完全访问权限。',
    duplicate_payment_reference: '该付款参考号已存在。请提交不同的付款参考号。',
    email_sent: '一封有关如何重设密码的说明的电子邮件已被发送。',
    exceed_items_per_submission: '您最多只能申请 {{numberOfItems}} 件商品的付款。',
    exceed_payment_reference_length: '付款参考号不得超过 {{allowedPaymentReferenceLength}} 个字。',
    forgot_pass: '我们将发送你电子邮件,其中包含有关如何重设密码的说明。',
    for_bank_details_and_contact: '有关银行账户详细信息和联系信息',
    get_started: '入门指南',
    insufficient_balance_topup: '0',
    insufficient_deposit: '抵押金不足',
    insufficient_balance: 'You have won this item with your deposit blocked in another auction. Please call {{phone}} to topup your balance and complete your purchase.',
    login_info: '您必须拥有足够的余额以参与竞标。每样物品的抵押金会被列在清单了。若您是得标者,正确余额将立即从您的账户中扣除。',
    message_sent_error: 'Error occurred while sending message!',
    message_sent: 'Your message has been sent!',
    message_subscribed: 'You have subscribed to our company newsletter',
    must_login_favorite: '您必须先登入后才能把此物品添加至收藏列表。',
    must_login_note: '您必须先登入后才能在此物品添加注释。',
    must_login_to_bid: '您必须先登入后才能查看开价和参与竞标。',
    no_longer: '此物品已被下架',
    not_logged: '您尚未登录。请登录或注册以参与竞标,购买物品等等。',
    on_hold: '此物品将保留以在将来拍卖。请单击收藏列表,以在竞标时得到通知。',
    or_send: '或发送我们一封电子邮件',
    payment_guideline_instruction_1: '您一次最多可以支付 5 件商品',
    payment_guideline_instruction_2: '您只能提交 1 张收据作为付款证明',
    payment_notification: '您有{{paymentTerm}}的时间支付购买费用，否则可能会产生滞纳金。',
    payment_success: '验证后,状态将在项目页面更新',
    payment_to_seller_important_note: '重要提示：此部分只能用于向 Pickles 付款。',
    payment_to_seller_instruction_intro: '卖家付款方式：',
    payment_to_seller_instruction_1: '转至”购买与出价”>”未付款”',
    payment_to_seller_instruction_2: '点击付款项目上的“查看详情”',
    payment_to_seller_instruction_3: '检查“查看合同”以获取卖方银行帐户详细信息和联系电话',
    payment_to_seller_instruction_4: '付款并向卖家提供付款证明',
    payment_to_seller_instruction_5: '付款验证后，卖方将通过电子邮件向您和 Pickles 发送释放邮件',
    payment_to_seller_instruction_6: 'Pickles 工作人员将批准付款，这将反映在项目详细信息屏幕上',
    pickles_verify_seller_payment: '向卖家付款将由 Pickles 验证',
    pre_bid: '预先竞标',
    refund: '如要申请退款，请发送电子邮件至refunds@pickles.my，并附上您的用户名和银行账户资料。我们的团队将在收到您的详细资料后处理退款。',
    reset_pass_email_sent: '密码重置电子邮件已成功发送。',
    reset_pass_sent: '密码重置电子邮件已成功发送。',
    reset_password: '忘记密码',
    still_problem: '若您有任何疑问或仍未在几分钟后收到电子邮件,请致电给我们',
    wait_1_hour: '您的账户尚未通过验证,请等待一个工作日以进行验证,或联系我们的支持团队以立即获得协助。',
    we_will_look: '我们将竭诚为您服务',
    you_must_logged: '你必须先登录,以查看开价和参与竞标。',
    your_account_has_not: '您的账户尚未被批准。请联系我们的客户支持。',
    your_bid_must_be_higher: '您的出价必须高于 {{value}}',
  },
  status: {
    e_bidding: '网上竞标',
    live_auction: '实时拍卖',
    lost: '失败',
    not_current_item: '不是当前物品',
    offer: '出价',
    on_hold: '此物品将保留以在将来拍卖。请单击收藏列表,以在竞标时得到通知。',
    outbid_lowercase: '正落后',
    outbid: '落后',
    winning_lowercase: '正领先',
    winning: '领先',
    won: '中标',
  },
  titles: {
    all_auctions: '所有拍卖会',
    apply_payment: '应用付款',
    auction_detail: '物品清单',
    awesome_news: 'Awesome news!',
    bid_history: '投标记录',
    car_details: '物品详情',
    connect_acc: '连接账号',
    custom_bid: '自定义出价',
    dashboard: '我的主页',
    duplicate_payment_reference: '付款重复',
    ebidding_auctions: '线上拍卖',
    enter_note: '添加笔记',
    error: '错误信息',
    failure: 'Failure!',
    filters: '筛选',
    gallery: '照片库',
    item_photos: '物品照片',
    live_auctions: '实时拍卖',
    make_payment: '付款',
    my_account: '我的账户',
    my_bidding_activity: '我的竞标活动',
    my_bids: '我的标价',
    my_offers: '我的出价',
    my_prebids: '我的最高自动出价',
    my_prebids_note:'仅限于现场拍卖',
    my_purchases: '我的购物清单',
    my_purchases_v2: '购买与出价',
    my_watchlist: '我的收藏列表',
    no_offers: '您尚未有任何出价记录',
    notes: '笔记',
    notice: '通告',
    offers_history: '出价记录',
    offers: '流拍列表',
    payment_submission_guidelines: '付款提交指南',
    payment_success: '您的付款证明已成功提交',
    pickles_auctions: 'Pickles Auctions',
    pos: '清单',
    purchase_details: '购买详情',
    purchases: '购物列表',
    reset_password: '密码重置',
    search_result: '搜索结果',
    search: '查找',
    select_date: '选择日期',
    select_items: '选择项目',
    sell_with_us: '跟我们一起卖',
    share: '分享',
    sorry: '抱歉！',
    submit_payment_proof: '请提交付款证明',
    success: 'Success!',
    top_up: '充值',
    update_require: '请更新Pickles应用程序',
    user_offers: '流拍列表',
    vehicle_list: '车辆清单',
  },
  messages: {
    Accepted: '您的标价已被接受',
    AdjustDown: '标价已调',
    CreditExceed: '保证金超额',
    FlaggedNoBuy: '无法购买',
    OtherBid: '继续出价',
    OutBid: '您的出价较为落后!',
    PriorProposal: '继续出价',
    ReppingItem: '此物品属于您',
    reserve_price_info: '本拍卖物无法提供底价。',
    Reset: '重置标价',
    ToFloor: 'Given to floor',
    TooLow: '出价太低',
  },
  errors: {
    contact_support: '注册用户时出现了错误。请联系我们的客户支持。',
    email_already_exists: '此邮址已被其他用户使用',
    email_exists: '此电子邮件地址已注册',
    email_not_register: '这邮址未被注册',
    error_oops: 'Oops!',
    error_title: '错误',
    ic_number_already_exists: '这身份证号码已被其他用户使用',
    ic_number_exists: '此身份证号码已注册',
    login_user_failure: '您输入了无效的用户名或错误的密码',
    token_expired_error: '您的登录活跃度已过期，请重新登录。',
    auth_error:'抱歉，处理您的请求时出现错误 {{errorCode}}，请稍后再试。如果问题仍然存在，请联系 {{phone}}。',
    client_error: '抱歉，处理您的请求时出现错误 {{errorCode}}，请稍后再试或联系 {{phone}}。',
    server_error: '处理您的请求时出现错误, 请稍后再试或联系 {{phone}}',
    timeout_error: 'Server is taking too long to respond. Please try in a few moments or contact {{phone}}.',
    connection_error: 'It seems that you do not have an active internet connection. Please try checking your data connection or reconnecting to Wi-Fi. Please contact {{phone}} if the problem still persists.',
    network_error: '出现不稳定的网络连接。请检查网络数据连接或刷新您的Wi-Fi.',
    cancel_error: 'It seems that your connection has an issue. Please try again.',
    no_internet: '没有互联网',
    offline: 'Offline',
    page_not_found: 'We can’t seem to find the page you’re looking for.',
    please_fill_next_fields: '请在下一个字段中输入有效数据',
    register_another_email: '您尝试注册的电邮地址已被使用。请用其他电邮地址注册',
    register_another_ic_number: '您尝试注册的身份证号码已被使用。请用其他身份证号码注册',
    register_another_username: '您尝试注册的用户名已被使用。请用其他用户名注册',
    register_user_failure: '用户注册失败',
    server_fields: {
      buyer_type: '买家类型',
      username: '用户名',
      gov_id: '身份证号码',
      email: '电子邮箱地址',
      phone: '手机号码',
    },
    still_pending_approval_new: '通常需要 1 个工作日。如果您需要紧急批准,请致电 {{phone}}',
    still_pending_approval: '您的账户仍在等待批准。我们通常需要一天的时间来审核您的申请。若您需要紧急批准,请联系{{phone}}',
    transaction_limit: '在线充值目前单笔交易限额为 {{maxAmount}}。请输入 {{minAmount}} 至 {{maxAmount}} 继续进行支付',
    username_already_exists: '此用户名已被其他用户使用',
    username_exists: '此用户名已注册',
  },
  transactions: {
    auto_settled: '自动结算',
    cash: '现金',
    check: '支票',
    credit_card: '信用卡',
    deposit: '充值',
    download: '下载',
    invoice_buyer: '买家佣金',
    invoice_consigner: '卖家费用',
    invoice_deposits: '物品抵押金',
    invoice: '清单',
    noTransactions: '没有交易',
    payment_method: '付款方式',
    payment: '款项',
    refund: '退款',
    title: '交易记录',
    view_older: '对于较早的交易记录，请联系info@pickles.my',
  },
  socket: {
    latency: 'Latency',
    ebid: {
      connect_error: 'Connection error',
      connected: 'E-Bidding Connected',
      disconnect: 'Connection Lost',
      reconnect_error: 'Reconnecting...',
      reconnect_failed: 'Reconnect failed',
      reconnected: 'Connection established.',
      reconnecting: 'Reconnecting...',
      retries: 'Retries Attempt',
    },
    live: {
      connect_error: 'Connection error',
      connect_timeout: 'Connection timeout',
      connected: 'Live Auction Connected',
      disconnect: 'Connection Lost',
      reconnect_error: 'Reconnecting...',
      reconnect_failed: 'Reconnect failed',
      reconnected: 'Connection established.',
      reconnecting: 'Reconnecting...',
      retries: 'Retries Attempt',
    },
  },
  fpx: {
    online_banking: '网上交易',
    amount: 'Amount',
    pay_with: '付款方式',
    payment_mode: 'Payment mode',
    select_payment_mode: '选择付款方式',
    select_bank: '选择银行',
    proceed: '继续',
    cancel: '取消',
    b2c: '个人银行业务',
    b2b: '企业银行业务',
    transaction_id: 'FPX交易ID',
    transaction_status: '交易状态',
    seller_order_number: `PICKLES订单号`,
    buyer_bank: '买方银行',
    transaction_amount: '交易金额',
    transaction_date: '交易日期及时间',
    transaction_limit: 'Top up via FPX has a minimum transaction limit of RM 2 and maximum limit of RM 10,000. Kindly enter any amount from RM 2 to RM 10,000 to proceed',
    close: '关闭',
    tnc_text: '点击“继续”按钮，即表示您同意',
    tnc_hyperlink: `FPX 的条款和条件`,
    successful: '成功',
    fail: '失败',
    transaction_not_found: '',
  }
};
